import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/Layouts/Layout';
import SEO from '../components/seo';
import { Link } from 'gatsby';

const Events = () => {
  return (
    <Layout>
      <SEO title='Events' />
      <section className='bg-gray-200 min-h-screen'>
        <div className='container mx-auto px-4 pb-20 -pt-24'>
          <div className='flex flex-wrap items-center justify-center text-center pt-24'>
            {/* <div className='w-full'>
              <div className='px-12 md:px-4 text-right mb-4'>
                <Link
                  to='/rsvp'
                  className='bg-orange-500 text-white active:bg-orange-600 font-semibold px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150'
                >
                  RSVP
                </Link>
              </div>
              <StaticImage
                src='../images/events/janmashtami-2023.jpg'
                formats={['AUTO', 'WEBP', 'AVIF']}
                className='max-w-full rounded-lg shadow-xl'
                alt='Chicago Bhulkas'
              />
            </div> */}
            <h1 className='text-3xl mt-32'>No Upcoming Events.</h1>
            {/* <div className='w-full mt-8 text-xl'>
              <hr className='border-gray-400' />
              <div className='py-3 text-left'>
                <h6 className='font-semibold mb-2'>March 24th, 2024</h6>
                <div className='flex flex-wrap items-center justify-between'>
                  <span>Holi & Fuldol Utsav</span>
                </div>
                <StaticImage
                  src='../images/hero/Holi.jpg'
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  className='max-w-580-px rounded-lg shadow-xl mt-3'
                  alt='Chicago Bhulkas'
                />
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Events;
